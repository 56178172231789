import React, { useContext } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
// import "./test-page.css"


const image1 = require('../../images/broadoak_1x1.jpg');

const image3 = require('../../images/chestdrill.jpg');
const image4 = require('../../images/HIP.png');




const TestPage = ({ markdownNodes }) => {
  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);

  const productsWarranties = markdownNodesFilter(
    markdownNodes,
    "products_and_warranties"
  )

  const goToProducts = () => {
    productsBlockRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  
  
  return (
    <div className="products-and-warranties content-page" >
      <Hero imageUrl="/images/products-warranties-banner.jpg" compact>
        <Heading level={1} underlined>
          Test Page
        </Heading>
      </Hero>
      {/* <Block >
        <div className="container u-layout--indent" style={{background: isDomestic ? 'red' : 'initial'}}>
          <div className="row">
            <Col8>
              <Heading level={3}>Commercial Test Title</Heading>              
            </Col8>
            <Col8>
              <button onClick={openModal}>Open Modal</button>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
                <button onClick={closeModal}>close</button>
                <div>I am a modal</div>
                <form>
                  <input />
                  <button>tab navigation</button>
                  <button>stays</button>
                  <button>inside</button>
                  <button>the modal</button>
                </form>
              </Modal>             
            </Col8>
          </div>
        </div>
      </Block>
      <Block >
        <div className="container" >
          <div className="row" style={{backgroundColor:"blue"}}>
            <Col8>
              <Heading level={3}>Image block</Heading>              
            </Col8>
          </div>
          <div className="row" style={{backgroundColor:"yellow"}}>
            <Col8>
            <div className="infocontainer">
              <img src="https://i.imgur.com/8kBZ3dV.jpg" />
              <button className="btn">Button</button>
            </div>              
            </Col8>
          </div>

        </div>
      </Block>            */}
      
    </div>
  )
}

export default TestPage
